import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Logo from '../../assets/asoko_logo.png'

import '../../style/login.scss'

const SessionTimeoutPage = () => {
  const { t } = useTranslation()

  const clickHandler = () => {
    const redirectPath = '/login'
    window.location.href = redirectPath
  }

  return (
    <div id='login-background'>
      <div id='login-container'>
        <div id='login-modal'>
          <img src={Logo} alt='Celestar logo' id='login-logo' />
          <div style={{ padding: '10px' }}>
            <Trans t={t} i18nKey="session_timeout" />
            <button
              className='btn primary'
              onClick={() => clickHandler()}
              style={{
                margin: '10px auto',
                display: 'block'
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionTimeoutPage
