import {
  ARRANGEMENT_DIRECTIONS,
  CARGO_TYPES,
  ENUM_CARGO_OPTIONS,
  ENUM_CARGO_TYPES,
  ENUM_STORAGE_OPTIONS,
  STORAGE_METHODS
} from "./index";

export const LANGUAGES = [
  {
    text: '日本語',
    key: 'ja',
  },
  {
    text: 'English',
    key: 'en',
  },
];

export const RECAPTCHA_KEY = "6Ldys7EjAAAAALMGRONj_6nEA7UgwTLOAj7r6wq2";

export const ROLE_TEXTS = {
  admin: 'role_admin',
  manager: 'role_manager',
  user: 'role_user',
  viewer: 'role_viewer',
};

export const EXEC_FETCH_INTERVAL_TIME = 1000 * 5  // 5 sec
export const THRESHOLD_OF_TIMEOUT = 1000 * 60 * 10 // 10 min

export const TIME_OF_SESSION_TIMEOUT = 1000 * 60 * 60 * 6 // 6h

export const DEFAULT_INPUT = {
  // Spatial information
  pillarSpanWidth: "11.00",
  pillarSpanDepth: "11.00",
  ceilingHeight: "5.50",
  fireCompartmentWidthSections: 3,
  fireCompartmentDepthSections: 4,
  floorLoadCapacity: "1.50",
  pillarSizeWidth: "1.00",
  pillarSizeDepth: "1.00",
  spandrelWallThickness: "0.18",


  cargoType: ENUM_CARGO_TYPES.pallet,
  palletOption: ENUM_CARGO_OPTIONS.pallet_jis,
  rollboxOption: ENUM_CARGO_OPTIONS.rollbox_1,
  foldingOption: ENUM_CARGO_OPTIONS.folding_container,

  cargoOption: CARGO_TYPES[0].options[0],
  cargoSpecs: {
    width: "1.10",
    depth: "1.10",
    height: "1.00",
    weight: "1,100",
  },
  cargoInfoCollapsed: {
    [ENUM_CARGO_OPTIONS.pallet_jis]: {
      width: "1.10",
      depth: "1.10",
      height: "1.00",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.pallet_cold]: {
      width: "1.00",
      depth: "1.20",
      height: "1.00",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.pallet_us]: {
      width: "1.02",
      depth: "1.22",
      height: "1.00",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.pallet_eu]: {
      width: "0.80",
      depth: "1.20",
      height: "1.00",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.pallet_beverage]: {
      width: "0.90",
      depth: "1.10",
      height: "1.00",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.rollbox_1]: {
      width: "1.04",
      depth: "1.04",
      height: "1.70",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.rollbox_2]: {
      width: "1.10",
      depth: "0.80",
      height: "1.70",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.folding_container]: {
      width: "1.04",
      depth: "1.04",
      height: "1.00",
      weight: "1,100",
    },
    [ENUM_CARGO_OPTIONS.carton_box]: {
      width: "1.10",
      depth: "0.80",
      height: "1.00",
      weight: "1,100",
    },
  },

  storageMethod: STORAGE_METHODS[0].text,

  storageInfoCollapsed: {
    [ENUM_STORAGE_OPTIONS.pallet_rack_1p_1s]: {
      width: "prSingleWidth",
      depth: "prSingleDepth",
      height: "prHeight",
      weight: "200",
    },
    [ENUM_STORAGE_OPTIONS.pallet_rack_1p_2s]: {
      width: "prSingleWidth",
      depth: "prDoubleDepth",
      height: "prHeight",
      weight: "100",
    },
    [ENUM_STORAGE_OPTIONS.pallet_rack_2p_2s]: {
      width: "prDoubleWidth",
      depth: "prDoubleDepth",
      height: "prHeight",
      weight: "400",
    },
    [ENUM_STORAGE_OPTIONS.pallet_rack_2p_1s]: {
      width: "prDoubleWidth",
      depth: "prSingleDepth",
      height: "prHeight",
      weight: "200",
    },
    [ENUM_STORAGE_OPTIONS.nesting_rack_1p_1s]: {
      width: "nrSingleWidth",
      depth: "nrSingleDepth",
      height: "nrHeight",
      weight: "50",
    },
    [ENUM_STORAGE_OPTIONS.nesting_rack_2p_2s]: {
      width: "nrDoubleWidth",
      depth: "nrDoubleDepth",
      height: "nrHeight",
      weight: "100",
    },
    [ENUM_STORAGE_OPTIONS.mdr_1w_1s]: {
      width: "mdrSingleWidth",
      depth: "mdrSingleDepth",
      height: "mdrHeight",
      rows: "mdrRows1w1s",
      levels: "mdrLevels"
    },
    [ENUM_STORAGE_OPTIONS.mdr_1w_2s]: {
      width: "mdrSingleWidth",
      depth: "mdrDoubleDepth",
      height: "mdrHeight",
      rows: "mdrRows1w2s",
      levels: "mdrLevels"
    },
    [ENUM_STORAGE_OPTIONS.mdr_2w_1s]: {
      width: "mdrDoubleWidth",
      depth: "mdrSingleDepth",
      height: "mdrHeight",
      rows: "mdrRows2w1s",
      levels: "mdrLevels"
    },
    [ENUM_STORAGE_OPTIONS.mdr_2w_2s]: {
      width: "mdrDoubleWidth",
      depth: "mdrDoubleDepth",
      height: "mdrHeight",
      rows: "mdrRows2w2s",
      levels: "mdrLevels"
    },
    [ENUM_STORAGE_OPTIONS.fc_1c_1s]: {
      width: "fcSingleWidth",
      depth: "fcSingleDepth",
      height: "fcHeight",
      levels: "fcLevels"
    },
    [ENUM_STORAGE_OPTIONS.fc_1c_2s]: {
      width: "fcSingleWidth",
      depth: "fcDoubleDepth",
      height: "fcHeight",
      levels: "fcLevels"
    },
    // [ENUM_STORAGE_OPTIONS.carton_boxes]: {
    //   width: "cbSingleWidth",
    //   depth: "cbSingleDepth",
    //   height: "cbHeight",
    //   levels: "cbLevels",
    // }
  },

  storageSpecs: {
    // Pallet rack
    prHeight: "4.8",
    prSingleWidth: "1.41",
    prSingleDepth: "1.10",
    prDoubleWidth: "2.61",
    prDoubleDepth: "2.30",


    // Nesting rack
    nrHeight: "1.77",
    nrSingleWidth: "1.35",
    nrSingleDepth: "1.20",
    nrDoubleWidth: "2.70",
    nrDoubleDepth: "2.4",

    // Medium duty rack
    mdrHeight: "2.1",
    mdrSingleWidth: "0.9",
    mdrSingleDepth: "0.45",
    mdrDoubleWidth: "1.8",
    mdrDoubleDepth: "0.93",

    // Folding container
    fcHeight: "0.33",
    fcSingleWidth: "0.64",
    fcSingleDepth: "0.44",
    fcDoubleDepth: "0.88",

    // Carton boxes
    cbHeight: "0.33",
    cbSingleWidth: "0.64",
    cbSingleDepth: "0.44",

    mdrLevels: 6,
    fcLevels: 6,
    cbLevels: 6,
    mdrRows1w1s: 5,
    mdrRows2w1s: 10,
    mdrRows2w2s: 20,
    mdrRows1w2s: 10,
  },
  arrangementDirection: ARRANGEMENT_DIRECTIONS[0].id,
  mainPassagewayWidth: "3.60",
  subPassagewayWidth: "3.00",
  clearancePillar: "0.20",
  clearanceFirewall: "0.20",
  clearanceFireshutter: "1.00",
  clearanceBeam: "0.30",
  monthlyRentPerUnit: "4,000",
};