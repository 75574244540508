import React, { useContext, useState, useEffect } from 'react'
import { get as lGet } from 'lodash'
import { AuthContext } from '../../../../app'
import {
  apiCreateWarehouse,
  apiGetAllCompanies,
  apiGetCompanyFromId,
  apiGetWarehouseFromId,
  apiUpdateWarehouseDetails
} from '../../../../utils/api'

import { hasShortNameError } from '../utils'
import { ERROR_MESSAGE_OF_DUPLICATE_WAREHOUSE } from './constants'
import { CircularProgress } from '@mui/material'

import ModalTitleLabel from '../../../atoms/ModalTitleLabel'
import ErrorMessage from '../../../atoms/ErrorMessage'
import InputForm from '../../../molecules/InputForm'
import SelectInputForm from '../../../molecules/SelectInputForm'
import ModalButtonsSet from '../../../molecules/ModalButtonsSet'

const EditWarehouseModal = (props) => {
  const { targetId, closeEditModal } = props
  const { userDetails } = useContext(AuthContext)
  const userCompanyId = userDetails['company_id']
  const userRole = userDetails['role']

  const [name, setName] = useState('')
  const [shortName, setShortName] = useState('')
  const [companiesList, setCompaniesList] = useState([])
  const [belongsCompanyListNo, setBelongsCompanyListNo] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  const isNameEmpty = !name || !shortName
  const disabled = isNameEmpty
  const isEditMode = !!targetId
  const modalTitle = isEditMode ? 'title_edit_warehouse' : 'title_create_warehouse'

  const fetchWarehouseFromId = async (companiesDetail=null) => {
    const {
      data: { data: companyDetails },
    } = await apiGetWarehouseFromId(targetId)
    const { name, short_name, company_id } = companyDetails[0]

    const rowCompaniesList = !!companiesList[0] ? companiesList : companiesDetail
    const companyIdList = rowCompaniesList.map(company => company['id'])
    const companyNo = companyIdList.indexOf(company_id)

    setName(name)
    setShortName(short_name)
    setBelongsCompanyListNo(companyNo)
  }

  const fetchCompaniesData = async () => {
    const {
      data: { data: companiesDetail },
    } = (userRole === 'admin')
      ? await apiGetAllCompanies()
      : await apiGetCompanyFromId(userCompanyId)
    setCompaniesList(companiesDetail)

    if (isEditMode) fetchWarehouseFromId(companiesDetail)
  }

  const initData = async () => {
    await fetchCompaniesData()
  }

  useEffect(() => {
    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createWarehouseSubmit = async (e) => {
    setIsInProgress(true)
    try {
      await apiCreateWarehouse({
        name: name,
        short_name: shortName,
        company_id: companiesList[belongsCompanyListNo]['id'],
      })
      window.location.reload()
    } catch (e) {
      const responseMessage = lGet(e, 'response.data.message')
      if (responseMessage === 'RECAPTCHA_ERROR') {
        setErrorMessage('Error occurred validating captcha. Please try again')
      } else if (responseMessage === ERROR_MESSAGE_OF_DUPLICATE_WAREHOUSE) {
        setErrorMessage('warehouse_duplicated')
      }
      setRefreshReCaptcha(!refreshReCaptcha)
      // console.log(e)
    }
    setIsInProgress(false)
  }

  const editWarehouseSubmit = async (targetWarehouseId) => {
    setIsInProgress(true)
    try {
      await apiUpdateWarehouseDetails({
        name: name,
        short_name: shortName,
        company_id: companiesList[belongsCompanyListNo]['id'],
        id: targetWarehouseId,
      })
      window.location.reload()
    } catch (e) {
      const responseMessage = lGet(e, 'response.data.message')
      if (responseMessage === 'RECAPTCHA_ERROR') {
        setErrorMessage('Error occurred validating captcha. Please try again')
      } else if (responseMessage === ERROR_MESSAGE_OF_DUPLICATE_WAREHOUSE) {
        setErrorMessage('warehouse_duplicated')
      }
      setRefreshReCaptcha(!refreshReCaptcha)
      // console.log(e)
    }
    setIsInProgress(false)
  }

  const returnIsError = () => {
    const isShortNameCorrect = hasShortNameError(shortName)
    if (!!shortName && !isShortNameCorrect) {
      setErrorMessage('warehouse_short_name_validation_error')
      return true
    } else {
      return false
    }
  }

  const handleRegisterWarehouse = async () => {
    const isError = await returnIsError()
    if (!isError) {
      if (isEditMode) {
        editWarehouseSubmit(targetId)
      } else {
        createWarehouseSubmit()
      }
    }
  }

    return (
      <div className='modal-wrapper'>
        <div
          className='modal-contents'
          onClick={e => { e.stopPropagation() }}
          style={{
            position: 'relative',
            opacity: isInProgress ? '.8' : '1'
          }}
        >
          <ModalTitleLabel i18nKey={modalTitle} />
          <div>
            <div className='flex'>
              <div className='input-wrapper half-width mr-4'>
                <InputForm
                  i18nKey='warehouse_name'
                  required={true}
                  type='text'
                  value={name}
                  setValue={setName}
                  autoComplete='register name'
                />
              </div>
              <div className='ml-4 input-wrapper half-width'>
                <InputForm
                  i18nKey='warehouse_short_name'
                  required={true}
                  type='text'
                  value={shortName}
                  setValue={setShortName}
                  autoComplete='register short_name'
                />
              </div>
            </div>
            <div className='flex'>
              <div className='input-wrapper half-width mr-4'>
                <SelectInputForm
                  i18nKey='company_name'
                  disabled={userRole !== 'admin'}
                  value={belongsCompanyListNo}
                  onChange={setBelongsCompanyListNo}
                  options={companiesList.map(value => value['name'])}
                />
              </div>
            </div>
            {isInProgress && (
              <div>
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%'
                  }}
                />
              </div>
            )}
          </div>

          <div className='flex'>
            <div className='input-wrapper mr-4'>
              <ErrorMessage i18nKey={errorMessage} />
            </div>
          </div>
          <ModalButtonsSet
            confirmButtonI18nKey='register'
            submitHandler={handleRegisterWarehouse}
            cancelHandler={closeEditModal}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }

export default EditWarehouseModal
