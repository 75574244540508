import React, {useEffect, useState} from "react";
//import _ from "lodash";
import {
  Link, Route,
  BrowserRouter, Routes, Navigate,
} from "react-router-dom";
import '@material-design-icons/font/filled.css';
import { List } from "@mui/material"
import MenuItem from "./components/molecules/LeftMenuItem";
import NestedMenu from "./components/organisms/NestedMenu";
import RevisionDisplay from './components/atoms/RevisionDisplay'
import MessageOfExecuteDone from './components/atoms/MessageOfExecuteDone'

import { RECAPTCHA_KEY } from './utils/constants';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Logo from "./assets/asoko_logo.png";
import {BACKEND_HOST, getQueryStringParam, LOGIN_STATES, removeQueryStringParam} from "./utils";
import axios from "axios";
import "./utils/i18n";
import {Trans, withTranslation} from "react-i18next";
import i18n from "./utils/i18n";
import PlanPage from "./pages/plan";
// import Home from "./pages/home";
import ServiceMenuHome from "./components/pages/servicePages/serviceMenu/ServiceMenuHome";
import UsersPage from "./components/pages/managementPages/users/UsersPage";
import LoginPage from "./pages/auth/login";
import RegisterPage from "./pages/auth/register";
import Cookies from "js-cookie";
import Asoko2Menu from "./components/pages/servicePages/serviceMenu/Asoko2Menu";
import Asokoplusmenu from "./pages/asokoplusmenu";
import PickingMenu from "./components/pages/servicePages/asoko2/picking/PickingMenu";
import PickingNewExec from './components/pages/servicePages/asoko2/picking/PickingNewExec'
import PickingResHis from './components/pages/servicePages/asoko2/picking/PickingResHis'
import SimulationMenu from './components/pages/servicePages/asoko2/simulation/SimulationMenu'
import SimulationNewExec from './components/pages/servicePages/asoko2/simulation/SimulationNewExec'
import SimulationResHis from './components/pages/servicePages/asoko2/simulation/SimulationResHis'
import CompaniesPage from "./components/pages/managementPages/companies/CompaniesPage";
import WarehousesPage from "./components/pages/managementPages/warehouses/WarehousesPage";
import SessionTimeoutPage from "./components/pages/SessionTimeoutPage";

export const AuthContext = React.createContext({
  userDetails: {
    email: '',
    first_name: '',
    id: '',
    image: '',
    lang: '',
    last_name: '',
    role: '',
    company_id: '',
  },
});

const PrivateApp = ({ t }) => {
  const [token, setToken] = useState(getQueryStringParam('t') || Cookies.get('token'));
  const [userDetails, setUserDetails ] = useState(null);
  const [lang, setLang] = useState(getQueryStringParam('l') || Cookies.get('lang'));
  const [ loginState, setLoginState ] = useState(LOGIN_STATES.INITIAL);
  const [ isExecProgress, setIsExecProgress ] = useState(false)
  const [ execStatus, setExecStatus ] = useState('')

  const getUserDetails = async () => {
    setLoginState(LOGIN_STATES.PROGRESS);
    try {
      const { data: { data: userDetails }} = await axios({
        method: 'GET',
        url: '/user/get-details',
        baseURL: BACKEND_HOST,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { email, first_name, id, image, lang, last_name, company_id, role } = userDetails;

      setUserDetails({
        email,
        first_name,
        id,
        image,
        lang,
        last_name,
        company_id,
        role,
      });
      setLoginState(LOGIN_STATES.LOGGED_IN);
    } catch(e) {
      setLoginState(LOGIN_STATES.INVALID_TOKEN);
    }
  };

  const checkLoginState = async () => {
    removeQueryStringParam('t');

    if(!token) {
      await setLoginState(LOGIN_STATES.NO_TOKEN);
      Cookies.remove('company_id')
      Cookies.remove('warehouse_id')
      Cookies.remove('company_no')
      Cookies.remove('warehouse_no')

      const loginTime = Cookies.get('login_time')
      if (!!loginTime) await setLoginState(LOGIN_STATES.SESSION_TIMEOUT)

      Cookies.remove('login_time')

      return;
    }
  }

  useEffect(() => {
    checkLoginState()
    getUserDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    Cookies.set('lang', lang);
    i18n.changeLanguage(lang);
    removeQueryStringParam('l');
  }, [lang]);

  const handleLogout = () => {
    setToken(null)
    Cookies.remove('token')
    Cookies.remove('login_time')
    window.location.href = '/login'
  }

  const contextValue = { userDetails };

  if(loginState === LOGIN_STATES.PROGRESS) {
    return (
      <div>Logging in...</div>
    );
  }

  if (loginState === LOGIN_STATES.NO_TOKEN) {
    return <Navigate to={`/login?redirect=${window.location.pathname}`} />;
  }

  if (loginState === LOGIN_STATES.SESSION_TIMEOUT) {
    return <Navigate to={`/sessiontimeout?redirect=${window.location.pathname}`} />
  }

  if(loginState === LOGIN_STATES.INVALID_TOKEN) {
    return (
      <div id="nopermission">
        <div>
          <img src={Logo} alt="Asoko logo" />
          <h3>Please login to view this page</h3>
          <p>This page is only accessible to authorised users.<br />
            Please click the button below to authenticate and login</p>
        </div>
        <button className="btn primary mt-4" onClick={() => { handleLogout() }}>Login</button>
      </div>
    );
  }

  if(loginState !== LOGIN_STATES.LOGGED_IN) {
    return null;
  }

  const parentMenuItemObject = {
    parentMenuIcon: 'management',
    parentMenuI18nKey: 'management',
  }

  const childrenMenuItemList = [
    {
      to: '/users',
      icon: 'people',
      i18nKey: 'account',
    },
    {
      to: '/companies',
      icon: 'company',
      i18nKey: 'company_name',
    },
    {
      to: '/warehouses',
      icon: 'warehouse',
      i18nKey: 'warehouse_name',
    },
  ]

  const revision = '2.0.xxx'
  const date = '2024/7/31'

  return (
    <AuthContext.Provider value={contextValue}>
      <div id="app-wrapper">
        <div id="sidebar">
          <Link to="/" id="logo">
            <img src={Logo} alt="Asoko Logo" id="logo-img" />
          </Link>
          <List>
            <MenuItem
              to="/"
              iconName="home"
              i18nKey="home"
            />
            <NestedMenu
              parentMenuItemObject={parentMenuItemObject}
              childrenMenuItemList={childrenMenuItemList}
            />
          </List>
          <MessageOfExecuteDone
            isExecProgress={isExecProgress}
            setIsExecProgress={setIsExecProgress}
            execStatus={execStatus}
            setExecStatus={setExecStatus}
          />
          <RevisionDisplay revision={revision} date={date} />
        </div>

        <div id="right-section">
          <div id="header">
            <div className="flex-grow" />
            <select
              className="input-field lang"
              onChange={e => {
                setLang(e.target.value);
              }}
              value={lang}
            >
              <option value="en">English</option>
              <option value="ja">日本語</option>
            </select>
            <button id="logout" onClick={() => { handleLogout() }}>
              <Trans t={t} i18nKey="logout" />
            </button>
          </div>
          <Routes>
            <Route path="" element={<ServiceMenuHome />} exact />
            <Route path="home" element={<ServiceMenuHome />} exact />
            <Route path="asoko2menu" element={<Asoko2Menu />} exact />
            <Route path="asokoplusmenu" element={<Asokoplusmenu />} exact />
            <Route path="pickingmenu" element={<PickingMenu />} exact />
            <Route path="picking/newexec" element={<PickingNewExec setIsExecProgress={setIsExecProgress} />} exact />
            <Route path="picking/reshis" element={<PickingResHis setExecStatus={setExecStatus} />} exact />
            <Route path="simulationmenu" element={<SimulationMenu />} exact />
            <Route path="simulation/newexec" element={<SimulationNewExec setIsExecProgress={setIsExecProgress}  />} exact />
            <Route path="simulation/reshis" element={<SimulationResHis setExecStatus={setExecStatus} />} exact />
            <Route path="users" element={<UsersPage />} />
            <Route path="plans/:plan_id" element={<PlanPage />} />
            <Route path="companies" element={<CompaniesPage />} />
            <Route path="warehouses" element={<WarehousesPage />} />
          </Routes>
        </div>
      </div>
    </AuthContext.Provider>
  )
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login">
          <Route index element={
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} useEnterprise="true">
              <LoginPage />
            </GoogleReCaptchaProvider>
          } />
        </Route>
        <Route path="/register">
          <Route index element={
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} useEnterprise="true">
              <RegisterPage />
            </GoogleReCaptchaProvider>
          } />
        </Route>
        <Route path="/sessiontimeout">
          <Route index element={
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} useEnterprise="true">
              <SessionTimeoutPage />
            </GoogleReCaptchaProvider>
          } />
        </Route>
        <Route path="*" index element={<PrivateApp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default withTranslation()(Router);
