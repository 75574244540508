const enTranslations = {
/*********************
  Captions and Screens name
*********************/
  home : 'Home',
  management: 'Management',
  account: 'Account',
  servicemenu:"Service Menu",
  asoko2: 'A-soko2',
  asoko2cmt:"Simulator for optimizing operations at warehouse",
  asokoplus:'A-soko+',
  asokopluscmt:"3D layout simulator for warehouse",
  newexec: 'NEW - EXECUTION',
  reshis:'RESULT - HISTORY',
  picking_newexec: 'Optimized picking list NEW - EXECUTION',
  picking_reshis:'Optimized picking list RESULT - HISTORY',
  simulation_newexec: 'Optimization scenario verification NEW - EXECUTION',
  simulation_reshis:'Optimization scenario verification RESULT - HISTORY',
  title_create_account: 'CREATE AN ACCOUNT',
  title_create_company: 'CREATE A CORPORATION',
  title_create_warehouse: 'CREATE A BRANCH',
  title_edit_account: 'EDIT AN ACCOUNT',
  title_edit_company: 'EDIT A CORPORATION',
  title_edit_warehouse: 'EDIT A BRANCH',
  opt_scenario_verif: 'Execute Scenarios for Optimization',
  opt_picking_list: 'Create Optimized Picking List',

/*********************
  Buttons
*********************/
  create_user: 'Add New Account',
  create_company: 'Add New Company',
  create_warehouse: 'Add New Branch',
  upload: 'Upload',
  execute: 'Execute',
  register: 'Register',
  edit: 'Edit',
  delete: 'Delete',
  cancel: 'Cancel',
  back:'◀Back',
  asoko2exec:"RUN▶",

  /*********************
  Input fields "Labels"
*********************/
  email: 'Email',
  confirm_email: 'Confirm Email',
  password: 'Password',
  confirm_password: 'Confirm Password',
  account_name: 'Account Name',
  company_name: 'Corporate Name',
  company_short_name: 'Corporate Code',
  warehouse_name: 'Branch Name',
  warehouse_short_name: 'Branch Code',
  full_name: 'Full Name',
  last_name: 'Last Name',
  first_name: 'First Name',
  role: 'Permission',
  select_file_for_upload: '▶ Select a File for Upload',
  message_upload_done: 'Upload completed',
  is_notification: 'Receive an email when simulation completes',
  export_result_file: 'Export a report of simulation results',
  export_excel: 'Export a picking list file in EXCEL format (in addition to CSV)',

/*********************
  List items "Labels"
*********************/
  simulation_model_files: 'Simulation Model Files',
  master_files: 'Data Files',
  updated_user: 'Updatede by',
  executed_user: 'Executed by',
  master_kind: 'Master kind',
  model_kind: 'Model kind',
  exec_filename: 'File Name',
  executed: 'Time of Execution',
  updated: 'Time of Update',
  result: 'Result',
  model_filename: 'File Name',
  master_filename: 'File Name',
  workload_list: 'Report of Workload',
  execution_log: 'Log File',
  
/*********************
  Master Classification
*********************/
  select_kind_of_master: 'Select the Files',
  parameter_master: 'Variable Settings',
  merchandise_master: 'Product Master',
  packing_master: 'Packing Master',
  delivery_master: 'Transportation Master',
  inventory_data: 'Inventory Data',
  shipment_data: 'Shipping Data',

/*********************
  Authorities
*********************/
  role_admin: 'Admin',
  role_manager: 'Manager',
  role_user: 'User',
  role_viewer: 'Viewer',

/*********************
  Model,Master kind
*********************/
  file_simulation_model: 'Model_Simulation',
  file_picking_model: 'Model_Picking List',
  file_data00: '00_Variable Settings File',
  file_data01: '01_Product Master File',
  file_data02: '02_Packing Master File',
  file_data03: '03_Transportation Master File',
  file_data04: '04_Inventory File',
  file_data05: '05_Shipping File',

/*********************
  Messages
*********************/
  exec_message: 'Do you want to run the simulation model?',
  delete_message: 'Are you sure you want to delete the file/record?',
  login_error: 'Login failed. Please check your email address or password.',
  email_does_not_match: 'The email address you entered does not match the registered email address.',
  password_does_not_match: 'The password you entered does not match the registered password.',
  assigned_warehouses_empty: 'Please select your Branch Name.',
  upload_all_files: 'Please upload all types of files.',
  exec_in_progress: 'Simulation Running.',
  exec_is_done: 'Simulation Completed.',
  exec_has_error: 'Simulation Error.',
  company_short_name_validation_error: 'Please use only capital letters or numbers for "Corporate Name (abbreviation)".',
  warehouse_short_name_validation_error: 'Please use only capital letters and numbers in "Branch Name (abbreviation)".',
  email_invalid_format: 'The email address format is invalid.',
  email_duplicated: 'Entered email address is already registered.',
  company_code_duplicated: 'Entered Corporate Code is already registered.',
  warehouse_duplicated: 'Entered Branch Code are already registered.',
  file_size_error: 'The maximum file size you can upload is 50MB.',
  session_timeout: 'Your session has timed out. Please log in again.',

/*********************
  Plant Simulation Erroe Message
*********************/
  E_00_00_00: 'Completed.',
  E_01_00_01: 'Loading the Variable Settings File failed.',
  E_01_00_02: 'The row and column definition in the Variable Settings File is invalid.',
  E_01_00_03: 'The Variable Settings File contains invalid values.',
  E_01_01_01: 'Loading the Product Master File failed.',
  E_01_01_02: 'The column definition of the Product Master File is invalid.',
  E_01_01_03: 'The Product Master File contains invalid values.',
  E_01_02_01: 'Loading the Packing Master File failed.',
  E_01_02_02: 'The column definition of the Packing Master File is invalid.',
  E_01_02_03: 'The Packing Master File contains invalid values.',
  E_01_03_01: 'Loading the Transportation Master File failed.',
  E_01_03_02: 'The column definition of the Transportation Master File is invalid.',
  E_01_03_03: 'The Transportation Master File contains invalid values.',
  E_01_05_01: 'Loading The Shipping (Picking) File failed.',
  E_01_05_02: 'The column definition for the Shipping (Picking) File is invalid.',
  E_01_05_03: 'The Shipping (Picking) File contains invalid values.',
  E_99_00_01: 'System error occurred. Please contact your administrator at support@a-soko.jp',

  // Login and register screens
  createAccount: 'Create an account',
  alreadyAccount: 'Have an account?',
  login: 'Login',
  loginAccount: 'Login to your account',

  lang:'Language',


  "pallet_rack_2p_1s": "2 Pallets / 1 Side",
  "pallet_rack_1p_1s": "1 Pallet / 1 Side",
  "pallet_rack_2p_2s": "2 Pallets / 2 Sides",
  "pallet_rack_1p_2s": "1 Pallet / 2 Sides",
  "nesting_rack_1p_1s": "1 Pallet / 1 Side",
  "nesting_rack_2p_2s": "2 Pallets / 2 Sides",
  "mdr_1w_1s": "0.9m / 1 Side",
  "mdr_2w_1s": "1.8m / 1 Side",
  "mdr_2w_2s": "0.9m / 2 Sides",
  "mdr_1w_2s": "1.8m / 2 Sides",
  "fc_1c_1s": "1 Case / 1 Side",
  "fc_1c_2s": "1 Case / 2 Sides",
  "carton_boxes": "",

  psch: "Pillar Span + Ceiling Height",
  efch: "Effect ceil height",
  mrpu: "Monthly rent per unit",
  unitPrice: "JPY/Tsubo",
};

export default enTranslations;
