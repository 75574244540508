import React, { useContext, useEffect, useState } from 'react'
import { get as lGet, omit } from 'lodash'
import Cookies from 'js-cookie'
import { AuthContext } from '../../../../app'
import { ROLE_TEXTS } from '../../../../utils/constants'
import { EMAIL_PATTERN, ERROR_MESSAGE_OF_DUPLICATE_EMAIL } from './constants'
import {
  apiCreateUser,
  apiUpdateUserDetails,
  apiGetUserFromId,
  apiGetAllCompanies,
  apiGetCompanyFromId,
  apiGetWarehouseFromCompanyId,
  apiGetWarehouseAssignFromUserId,
  apiUpdateWarehouseAssignFromUserId
} from '../../../../utils/api'
import { CircularProgress } from '@mui/material'

import ModalTitleLabel from '../../../atoms/ModalTitleLabel'
import ErrorMessage from '../../../atoms/ErrorMessage'
import Label from '../../../atoms/Label'
import InputForm from '../../../molecules/InputForm'
import SelectInputForm from '../../../molecules/SelectInputForm'
import FullNameInputForms from '../../../organisms/FullNameInputForms'
import CheckboxForm from '../../../organisms/CheckboxForm'
import ModalButtonsSet from '../../../molecules/ModalButtonsSet'

const EditUserModal = (props) => {
  const { targetId, closeEditModal } = props
  const { userDetails } = useContext(AuthContext)
  const userRole = userDetails['role']
  const userCompanyId = userDetails['company_id']

  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [email, setEmail] = useState('')
  const [isEmailChanged, setIsEmailChanged] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [role, setRole] = useState('viewer')
  const [companiesList, setCompaniesList] = useState([])
  const [selectedCompanyNo, setSelectedCompanyNo] = useState(0)
  const [warehousesList, setWarehousesList] = useState([])
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState([])
  const [isNotification, setIsNotification] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  const isEditMode = !!targetId
  const modalTitle = isEditMode ? 'title_edit_account' :  'title_create_account'
  const lang = Cookies.get('lang') || 'ja'
  const roleOptions =
    userRole === 'admin' ? ROLE_TEXTS :
    userRole === 'manager' ? omit(ROLE_TEXTS, ['admin']) :
    userRole === 'user' ? omit(ROLE_TEXTS, ['admin', 'manager', 'viewer']) :
    userRole === 'viewer' ? omit(ROLE_TEXTS, ['admin', 'manager', 'user']) : []

  const isPasswordEmpty = isEditMode ? (isPasswordChanged && !password) : !password
  const disabled = !fname || !lname || !email || isPasswordEmpty

  const isEmailFormatCorrect = (email) => {
    const result = EMAIL_PATTERN.test(email)

    return result
  }

  const returnIsError = () => {
    if (isEmailChanged && email !== confirmEmail) {
      setErrorMessage('email_does_not_match')
      return true
    } else if (!isEmailFormatCorrect(email)) {
      setErrorMessage('email_invalid_format')
      return true
    } else if (isPasswordChanged && password !== confirmPassword) {
      setErrorMessage('password_does_not_match')
      return true
    }  else if (selectedWarehouseIds.length === 0) {
      setErrorMessage('assigned_warehouses_empty')
      return true
    } else {
      setErrorMessage('')
      return false
    }
  }

  const createUserSubmit = async () => {
    setIsInProgress(true)
    try {
      const {
        data: { data },
      } = await apiCreateUser({
        first_name: fname,
        last_name: lname,
        email,
        password,
        role: role,
        company_id: companiesList[selectedCompanyNo]['id'],
        is_notification: isNotification,
      })
      const userId = data.userId

      await apiUpdateWarehouseAssignFromUserId({user_id: userId, warehouse_list: selectedWarehouseIds})
      window.location.reload()
    } catch (e) {
      const responseMessage = lGet(e, 'response.data.message')
      if (responseMessage === 'RECAPTCHA_ERROR') {
        setErrorMessage('Error occurred validating captcha. Please try again')
      } else if (responseMessage === ERROR_MESSAGE_OF_DUPLICATE_EMAIL) {
        setErrorMessage('email_duplicated')
      }
      setRefreshReCaptcha(!refreshReCaptcha)
      // console.log(e)
    }
    setIsInProgress(false)
  }

  const editUserSubmit = async (targetUserId) => {
    setIsInProgress(true)
    try {
      await apiUpdateUserDetails({
        first_name: fname,
        last_name: lname,
        email,
        password,
        role: role,
        company_id: companiesList[selectedCompanyNo]['id'],
        is_notification: isNotification,
        user_id: targetUserId,
      })

      await apiUpdateWarehouseAssignFromUserId({user_id: targetUserId, warehouse_list: selectedWarehouseIds})

      window.location.reload()
    } catch (e) {
      const responseMessage = lGet(e, 'response.data.message')
      if (responseMessage === 'RECAPTCHA_ERROR') {
        setErrorMessage('Error occurred validating captcha. Please try again')
      } else if (responseMessage === ERROR_MESSAGE_OF_DUPLICATE_EMAIL) {
        setErrorMessage('email_duplicated')
      }
      setRefreshReCaptcha(!refreshReCaptcha)
      // console.log(e)
    }
    setIsInProgress(false)
  }

  const handleRegisterUser = async () => {
    const isError = await returnIsError()
    if (!isError) {
      if (targetId === null) {
        createUserSubmit()
      } else {
        editUserSubmit(targetId)
      }
    }
  }

  const fetchCompaniesData = async () => {
    const {
      data: { data: companiesDetail },
    } = (userRole === 'admin')
      ? await apiGetAllCompanies()
      : await apiGetCompanyFromId(userCompanyId)
    setCompaniesList(companiesDetail)

    if (isEditMode) {
      fetchUserFromId(companiesDetail)
    } else {
      await fetchWarehousesData(companiesDetail[0]['id'])
    }
  }

  const fetchWarehousesData = async (companyId=null) => {
    const canChangeCompanies = (userRole === 'admin' || userRole === 'manager')
    if (!canChangeCompanies || !!companyId) {
      const {
        data: { data: warehousesDetails },
      } = await apiGetWarehouseFromCompanyId(!!companyId ? companyId : userCompanyId)
      setWarehousesList(warehousesDetails)
    }
  }

  const fetchUserFromId = async (companiesDetail=null) => {
    const {
      data: { data: userDetails },
    } = await apiGetUserFromId(targetId)
    const { first_name, last_name, email, role, company_id, is_notification } = userDetails
    const rowCompaniesList = !!companiesList[0] ? companiesList : companiesDetail
    const companyIdList = rowCompaniesList.map(company => company['id'])
    const companyNo = companyIdList.indexOf(company_id)
    setFname(first_name)
    setLname(last_name)
    setEmail(email)
    setRole(role)
    setIsNotification(is_notification)
    setSelectedCompanyNo(companyNo)
    setIsEmailChanged(false)
    setIsPasswordChanged(false)
    await fetchWarehousesData(company_id)
    await fetchWarehouseAssignFromUserId()
  }

  const fetchWarehouseAssignFromUserId = async () => {
    const {
      data: { data: warehouseAssignDetails },
    } = await apiGetWarehouseAssignFromUserId(targetId)
    const defaultWarehouseIds = warehouseAssignDetails.map(
      warehouseAssign => warehouseAssign['warehouse_id']
      )
    const updatedIdList = [...defaultWarehouseIds]
    setSelectedWarehouseIds(updatedIdList)

  }

  const handleChangeCompany = async () => {
    const companyId = companiesList[selectedCompanyNo]
      ? companiesList[selectedCompanyNo]['id']
      : ''
    if (!!companyId) {
      const {
        data: { data: details },
      } = await apiGetWarehouseFromCompanyId(companyId)
      setWarehousesList(details)
    } else {
      setWarehousesList([])
    }
    selectedWarehouseIds.length > 0 && setSelectedWarehouseIds([])
  }

  const initData = async () => {
    await fetchCompaniesData()
    if (!isEditMode) await fetchWarehousesData()
  }

  useEffect(() => {
    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleChangeCompany()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyNo])

  const handleCheckboxOnChange = (isChecked, targetWarehouseId) => {
    if (isChecked) {
      const updatedIdList = [
        ...selectedWarehouseIds,
        targetWarehouseId
      ]
      setSelectedWarehouseIds(updatedIdList)
    } else {
      const updatedIdList = selectedWarehouseIds.filter(id => id !== targetWarehouseId)
      setSelectedWarehouseIds(updatedIdList)
    }
  }

  const handleInputEmail = (email) => {
    setEmail(email)
    setIsEmailChanged(true)
  }

  const handleInputPassword = (password) => {
    setPassword(password)
    setIsPasswordChanged(true)
  }

    return (
      <div className='modal-wrapper'>
        <div
          className='modal-contents'
          onClick={e => { e.stopPropagation() }}
          style={{
            position: 'relative',
            opacity: isInProgress ? '.8' : '1'
          }}
        >
          <ModalTitleLabel i18nKey={modalTitle} />
          <div>
            <FullNameInputForms
              firstName={fname}
              setFirstName={setFname}
              lastName={lname}
              setLastName={setLname}
              lang={lang}
            />
            <div className='flex'>
              <div className='input-wrapper half-width mr-4'>
                <InputForm
                  i18nKey='email'
                  required={true}
                  type='email'
                  value={email}
                  setValue={handleInputEmail}
                  autoComplete='off'
                />
              </div>
              <div className='input-wrapper half-width ml-4'>
                <InputForm
                  i18nKey='confirm_email'
                  type='email'
                  value={confirmEmail}
                  setValue={setConfirmEmail}
                  disabled={isEditMode && !isEmailChanged}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='flex'>
              <div className='input-wrapper mr-4'>
                <InputForm
                  i18nKey='password'
                  required={true}
                  minLength={8}
                  type='password'
                  value={password}
                  dataId='password'
                  setValue={handleInputPassword}
                  autoComplete='off'
                />
              </div>
              <div className='input-wrapper ml-4'>
                <InputForm
                  i18nKey='confirm_password'
                  minLength={8}
                  type='password'
                  value={confirmPassword}
                  dataId='confirmPassword'
                  setValue={setConfirmPassword}
                  disabled={isEditMode && !isPasswordChanged}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='flex'>
              <div className='input-wrapper'>
                <SelectInputForm
                  i18nKey='role'
                  value={role}
                  onChange={setRole}
                  options={roleOptions}
                />
              </div>
            </div>
            <div className='flex'>
              <div className='input-wrapper mr-4'>
                <SelectInputForm
                  i18nKey='company_name'
                  disabled={userRole !== 'admin'}
                  value={selectedCompanyNo}
                  onChange={setSelectedCompanyNo}
                  options={companiesList.map(value => value['name'])}
                />
              </div>
              <div className='input-wrapper mr-4'>
                <CheckboxForm
                  i18nKey='warehouse_name'
                  disabled={userRole !== 'admin' && userRole !== 'manager'}
                  onChange={handleCheckboxOnChange}
                  optionsList={warehousesList}
                  defaultCheckedList={isEditMode ? selectedWarehouseIds : null}
                />
              </div>
            </div>
            <div className='flex'>
              <div className='input-wrapper'>
                <input
                  type='checkbox'
                  id='isNotification'
                  onChange={(e) => setIsNotification(e.target.checked)}
                  checked={isNotification}
                />
                <Label i18nKey='is_notification' inputFor='isNotification' />
              </div>
            </div>
            {isInProgress && (
              <div>
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%'
                  }}
                />
              </div>
            )}
          </div>

          <div className='flex'>
            <div className='input-wrapper mr-4'>
              <ErrorMessage i18nKey={errorMessage} />
            </div>
          </div>
          <ModalButtonsSet
            confirmButtonI18nKey='register'
            submitHandler={handleRegisterUser}
            cancelHandler={closeEditModal}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }

export default EditUserModal
