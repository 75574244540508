import React, { useState, useEffect } from 'react'
import { get as lGet } from 'lodash'
import {
  apiCreateCompany,
  apiGetCompanyFromId,
  apiUpdateCompanyDetails
} from '../../../../utils/api'
import { CircularProgress } from '@mui/material'

import { hasShortNameError } from '../utils'

import ModalTitleLabel from '../../../atoms/ModalTitleLabel'
import InputForm from '../../../molecules/InputForm'
import ErrorMessage from '../../../atoms/ErrorMessage'
import ModalButtonsSet from '../../../molecules/ModalButtonsSet'
import { ERROR_MESSAGE_OF_DUPLICATE_COMPANY_CODE } from './constants'

const EditCompanyModal = (props) => {
  const { targetId, closeEditModal } = props

  const [name, setName] = useState('')
  const [shortName, setShortName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)

  const isNameEmpty = !name || !shortName
  const disabled = isNameEmpty
  const isEditMode = !!targetId
  const modalTitle = isEditMode ? 'title_edit_company' : 'title_create_company'

  const fetchCompanyFromId = async () => {
    const {
      data: { data: companyDetails },
    } = await apiGetCompanyFromId(targetId)
    const { name, short_name } = companyDetails[0]
    setName(name)
    setShortName(short_name)
  }

  const createCompanySubmit = async () => {
    setIsInProgress(true)
    try {
      await apiCreateCompany({
        name: name,
        short_name: shortName,
      })
      window.location.reload()
    } catch (e) {
      const responseMessage = lGet(e, 'response.data.message')
      if (responseMessage === 'RECAPTCHA_ERROR') {
        setErrorMessage('Error occurred validating captcha. Please try again')
      } else if (responseMessage === ERROR_MESSAGE_OF_DUPLICATE_COMPANY_CODE) {
        setErrorMessage('company_code_duplicated')
      }
      setRefreshReCaptcha(!refreshReCaptcha)
      // console.log(e)
    }
    setIsInProgress(false)
  }

  const editCompanySubmit = async (targetId) => {
    setIsInProgress(true)
    try {
      await apiUpdateCompanyDetails({
        name: name,
        short_name: shortName,
        id: targetId,
      })
      window.location.reload()
    } catch (e) {
      const responseMessage = lGet(e, 'response.data.message')
      if (responseMessage === 'RECAPTCHA_ERROR') {
        setErrorMessage('Error occurred validating captcha. Please try again')
      } else if (responseMessage === ERROR_MESSAGE_OF_DUPLICATE_COMPANY_CODE) {
        setErrorMessage('company_code_duplicated')
      }
      setRefreshReCaptcha(!refreshReCaptcha)
      // console.log(e)
    }
    setIsInProgress(false)
  }

  const returnIsError = () => {
    const isShortNameCorrect = hasShortNameError(shortName)
    if (!!shortName && !isShortNameCorrect) {
      setErrorMessage('company_short_name_validation_error')
      return true
    } else {
      return false
    }
  }

  const handleRegisterCompany = async () => {
    const isError = await returnIsError()
    if (!isError) {
      if (isEditMode) {
        editCompanySubmit(targetId)
      } else {
        createCompanySubmit()
      }
    }
  }

  useEffect(() => {
    if (isEditMode) fetchCompanyFromId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
      <div className='modal-wrapper'>
        <div
          className='modal-contents'
          onClick={e => { e.stopPropagation() }}
          style={{
            position: 'relative',
            opacity: isInProgress ? '.8' : '1'
          }}
        >
          <ModalTitleLabel i18nKey={modalTitle} />
          <div className='flex'>
            <div className='input-wrapper half-width mr-4'>
              <InputForm
                i18nKey='company_name'
                required={true}
                type='text'
                value={name}
                setValue={setName}
                autoComplete='register name'
              />
            </div>
            <div className='ml-4 input-wrapper half-width'>
              <InputForm
                i18nKey='company_short_name'
                required={true}
                type='text'
                value={shortName}
                setValue={setShortName}
                autoComplete='register short_name'
              />
            </div>
            {isInProgress && (
              <div>
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%'
                  }}
                />
              </div>
            )}
          </div>

          <div className='flex'>
            <div className='input-wrapper mr-4'>
              <ErrorMessage i18nKey={errorMessage} />
            </div>
          </div>
          <ModalButtonsSet
            confirmButtonI18nKey='register'
            submitHandler={handleRegisterCompany}
            cancelHandler={closeEditModal}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }

export default EditCompanyModal
