import React, { useEffect } from 'react'
import Cookies from 'js-cookie'

import { checkSessionTimeout } from '../../utils/index'

import H2Title from '../atoms/H2Title'
import ServiceMenuCard from '../molecules/ServiceMenuCard'
import ServiceButtonsSet from '../molecules/ServiceButtonsSet'

const ServiceMenuTemplate = (props) => {
  const {
    pageTitle='',
    upperNavLinkTitle='',
    upperNavLinkSubTitle='',
    upperNavTo='',
    lowerNavLinkTitle='',
    lowerNavLinkSubTitle='',
    lowerNavTo='',
    backTo=''
  } = props.props

  const isDisplayBackButton = !!backTo

  useEffect(() => {
    const loginTime = Cookies.get('login_time')
    checkSessionTimeout(loginTime)
  }, [])

  return (
    <div className='padding2'>
      <H2Title type='home-menu' decoration='▶' i18nKey={pageTitle} />
      <ServiceMenuCard
        navTo={upperNavTo}
        navLinkTitle={upperNavLinkTitle}
        navLinkSubTitle={upperNavLinkSubTitle}
      />
      <ServiceMenuCard
        navTo={lowerNavTo}
        navLinkTitle={lowerNavLinkTitle}
        navLinkSubTitle={lowerNavLinkSubTitle}
      />
      {isDisplayBackButton && <ServiceButtonsSet backTo={backTo} />}
    </div>
  )
}

export default ServiceMenuTemplate
