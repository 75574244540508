import React, { useState } from 'react';
import { get as lGet } from 'lodash';
import Cookies from 'js-cookie';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, withTranslation } from 'react-i18next';
import LoginHoc from './login_hoc';
import { getQueryStringParam } from '../../utils';
import { apiCreateUser } from '../../utils/api';
import { Link } from 'react-router-dom';

import LanguageSwitcher from '../../components/molecules/LanguageSwitcher';
import InputForm from '../../components/molecules/InputForm';

const RegisterPage = ({ t }) => {
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [token, setToken] = useState('');

  const verifyRecaptchaCallback = React.useCallback((token) => {
    setToken(token);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const redirectPath = getQueryStringParam('redirect') || '/products';
    const lang = Cookies.get('lang') || 'ja';
    try {
      const {
        data: { data },
      } = await apiCreateUser({
        email,
        password,
        lang,
        token,
        first_name: fname,
        last_name: lname,
        role: 'viewer', // ToFix: roleは必須、新規登録画面での扱いを確認する
      });
      Cookies.set('token', data);
      const loginTime = new Date().getTime()
      Cookies.set('login_time', loginTime)
      window.location.href = redirectPath;
    } catch (e) {
      if (lGet(e, 'response.data.message') === 'RECAPTCHA_ERROR') {
        setError('Error occurred validating captcha. Please try again');
      }
      setRefreshReCaptcha(!refreshReCaptcha);
      // console.log(e);
    }
  };

  const passwordsDontMatch = password && confirmPassword && password !== confirmPassword;

  return (
    <LoginHoc wide>
      <h3 id="login-heading">
        <Trans t={t} i18nKey="createAccount" />
      </h3>
      <form onSubmit={onSubmit} className="login-form">
        <div className="flex">
          <div className="input-wrapper half-width mr-4">
            <InputForm
              i18nKey="first_name"
              required={true}
              type="text"
              value={fname}
              setValue={setFname}
              autoComplete="register first_name"
            />
          </div>
          <div className="ml-4 input-wrapper half-width">
            <InputForm
              i18nKey="last_name"
              type="text"
              value={lname}
              setValue={setLname}
              autoComplete="register last_name"
            />
          </div>
        </div>
        <div className="input-wrapper">
          <InputForm
            i18nKey="email"
            required={true}
            type="email"
            value={email}
            setValue={setEmail}
            autoComplete="login email"
          />
        </div>
        <div className="flex">
          <div className="input-wrapper mr-4">
            <InputForm
              i18nKey="password"
              required={true}
              minLength={8}
              type="password"
              value={password}
              dataId="password"
              setValue={setPassword}
              autoComplete="register password"
            />
          </div>
          <div className="input-wrapper ml-4">
            <InputForm
              i18nKey="confirm_password"
              required={true}
              minLength={8}
              type="password"
              value={confirmPassword}
              dataId="confirmPassword"
              setValue={setConfirmPassword}
              autoComplete="register confirmPassword"
            />
          </div>
        </div>
        <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
        {error && <div className="login-error">{error}</div>}
        {passwordsDontMatch && (
          <div className="login-error">The passwords entered do not match</div>
        )}
        <button className="login-submit" type="submit" disabled={passwordsDontMatch}>
          <Trans t={t} i18nKey="register" />
        </button>
        <p id="login-create">
          <Trans t={t} i18nKey="alreadyAccount" />{' '}
          <Link to="/login">
            <Trans t={t} i18nKey="login" />
          </Link>
        </p>
        <div className="login-lang-wrapper">
          <LanguageSwitcher small />
        </div>
      </form>
    </LoginHoc>
  );
};

export default withTranslation()(RegisterPage);
