import React, { useContext, useEffect, useState } from 'react'
import { apiGetAsoko2PickingModels, apiGetAsoko2PickingMasters, apiAsoko2PickingExec, apiAsoko2PickingUpload } from '../../../../../utils/api'
import { AuthContext } from '../../../../../app'
import Cookies from 'js-cookie'

import {
  apiGetAllCompanies,
  apiGetCompanyFromId,
  apiGetWarehouseFromCompanyId,
  apiGetWarehouseAssignFromUserId,
} from '../../../../../utils/api'
import { checkSessionTimeout } from '../../../../../utils'

import ErrorMessage from '../../../../atoms/ErrorMessage'
import ServiceButtonsSet from '../../../../molecules/ServiceButtonsSet'
import ExportResultFileSelector from '../../../../molecules/ExportResultFileSelector'
import ExecuteModal from '../../../../organisms/ExecuteModal'
import ExecuteListTable from '../../../../organisms/ExecuteListTable'
import BasicInfoPanel from '../../../../organisms/BasicInfoPanel'

const PickingNewExec = ({ setIsExecProgress }) => {
  const { userDetails } = useContext(AuthContext)
  const { company_id, role, id } = userDetails
  const [ modelList, setModelList ] = useState(null)
  const [ masterList, setMasterList ] = useState(null)

  const [companiesList, setCompaniesList] = useState([])
  const [selectedCompanyNo, setSelectedCompanyNo] = useState(0)
  const [warehousesList, setWarehousesList] = useState([])
  const [selectedWarehouseNo, setSelectedWarehouseNo] = useState(0)
  const [initFlg, setInitFlg] = useState(false)

  const [ isExecModalOpen, setIsExecModalOpen ] = useState(false)
  const [ isInProgress, setIsInProgress ] = useState(false)
  const [ isChangeFlag, setIsChangeFlag ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')

  const [ isExportExcelFormat, setIsExportExcelformat ] = useState(false)

  const userRole = userDetails['role']

  const onExec = async (e) => {
    const cookies_warehouseId = Cookies.get('warehouse_id')

    const excelFlag = isExportExcelFormat? 'true' : 'false'
    try {
      setIsInProgress(true)
      const {
        data: { data: res },
      } = await apiAsoko2PickingExec({
        warehouse_id: cookies_warehouseId,
        account: userDetails.email,
        userFirstName: userDetails.first_name,
        userLastName: userDetails.last_name,
        excelFlag: excelFlag
      })
      const jobId = res['job_id']
      // console.log('job_id: ',jobId)
      Cookies.set('kind', 'p')
      Cookies.set('exec_warehouse_id', cookies_warehouseId)
      Cookies.set('job_id', jobId)
      const execTime = new Date().getTime()
      Cookies.set('exec_time', execTime)
      Cookies.set('exec_status', 'executing')
      setIsExecProgress(true)
    } catch (e) {
      setErrorMessage('exec_has_error');
    }

    setIsInProgress(false)
    setIsExecModalOpen(false)
  }

  const returnIsAllFilesExists = () => {
    const isExistModel = !!modelList ? !modelList.find(model => model[2] === '') : false
    const isExistAllMaster = !!masterList ? !masterList.find(master => master[2] === '') : false

    return isExistModel && isExistAllMaster
  }

  const handleModalOpen = () => {
    if (returnIsAllFilesExists()) {
      setErrorMessage('')
      setIsExecModalOpen(true)
    } else {
      setErrorMessage('upload_all_files')
    }
  }

  const fetchPanelData = async () => {
    const companyIdFromCookie =
      (role === 'admin' && Cookies.get('company_id'))
        ? Cookies.get('company_id')
        : company_id

    const {
      data: { data: details_c },
    } = (role === 'admin')
      ? await apiGetAllCompanies()
      : await apiGetCompanyFromId(companyIdFromCookie)

    const indexOfCompanyNo = details_c.indexOf(
      details_c.find(details => details['id'] === companyIdFromCookie)
    )
    const companyNoFromCookie = !!indexOfCompanyNo
      ? indexOfCompanyNo
      : (role === 'admin' && Cookies.get('company_no'))
        ? Number(Cookies.get('company_no'))
        : 0

    setCompaniesList(details_c)
    setSelectedCompanyNo(companyNoFromCookie)

    const warehouseNoFromCookie =
      Cookies.get('warehouse_no')
        ? Number(Cookies.get('warehouse_no'))
        : 0

    const {
      data: { data: details_w },
    } = await apiGetWarehouseFromCompanyId(companyIdFromCookie)

    const {
      data: { data: details_assigned },
    } = await apiGetWarehouseAssignFromUserId(id)

    const filteredDetails_w =
      (role === 'admin')
        ? details_w
        : details_w.filter(
            warehouse => details_assigned.find(
              assigned => assigned['warehouse_id'] === warehouse['id']
            )
          )

    setWarehousesList(filteredDetails_w)
    setSelectedWarehouseNo(warehouseNoFromCookie)

    Cookies.set('company_no', companyNoFromCookie)
    Cookies.set('company_id', details_c[companyNoFromCookie]['id'])
    Cookies.set('warehouse_no', warehouseNoFromCookie)
    Cookies.set('warehouse_id', filteredDetails_w[warehouseNoFromCookie]['id'])
    setInitFlg(true)
  }

  const fetchListData = async () => {
    const cookies_warehouseId = Cookies.get('warehouse_id')

    if(!!cookies_warehouseId) {
      const {
        data: { data: model },
      } = await apiGetAsoko2PickingModels(cookies_warehouseId)
      setModelList(model)

      const {
        data: { data: master },
      } = await apiGetAsoko2PickingMasters(cookies_warehouseId)
      setMasterList(master)
    }
  }

  const initData = async () => {
    await fetchPanelData()
    await fetchListData()
  }

  useEffect(() => {
    if(isChangeFlag) {
      fetchListData()
      setErrorMessage('')
      setIsChangeFlag(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeFlag])

  const fileUpload = async (formData) => {
    await apiAsoko2PickingUpload(formData)
    fetchListData()
  }

  useEffect(() => {
    const loginTime = Cookies.get('login_time')
    checkSessionTimeout(loginTime)

    initData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const props = {
    i18nKey: 'picking_newexec',
    handleSelectId: setIsChangeFlag,
    companiesList: companiesList,
    selectedCompanyNo: selectedCompanyNo,
    setSelectedCompanyNo: setSelectedCompanyNo,
    warehousesList: warehousesList,
    setWarehousesList: setWarehousesList,
    selectedWarehouseNo: selectedWarehouseNo,
    setSelectedWarehouseNo: setSelectedWarehouseNo,
    fetchPanelData: fetchPanelData,
    initFlg: initFlg,
  }

  return (
    <div className='padding2'>
      <BasicInfoPanel props={props} />
      <ExecuteListTable
        titleI18nKey='simulation_model_files'
        kind='model'
        role={userRole}
        list={modelList}
        handleFileUpload={fileUpload}
        setErrorMessage={setErrorMessage}
        marginBottom='20px'
      />
      <ExecuteListTable
        titleI18nKey='master_files'
        kind='master'
        role={userRole}
        list={masterList}
        handleFileUpload={fileUpload}
        setErrorMessage={setErrorMessage}
        mode='picking'
      />
      <ExportResultFileSelector onChangeHandle={setIsExportExcelformat} />
      <div className='flex'>
        <div className='input-wrapper mr-4'>
          <div style={{ height: '25px' }}>
            <ErrorMessage i18nKey={errorMessage} />
          </div>
        </div>
      </div>
      <ServiceButtonsSet
        backTo='/pickingmenu'
        role={userRole}
        handleExecute={handleModalOpen}
      />
      {isExecModalOpen && (
        <ExecuteModal
          setIsModalOpen={setIsExecModalOpen}
          isInProgress={isInProgress}
          onExec={onExec}
          modelList={modelList}
          masterList={masterList}
        />
      )}
    </div>
  )
}

export default PickingNewExec
