import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { checkSessionTimeout } from '../../utils'

import ListTable from '../organisms/ListTable'
import Button from '../atoms/Button'

import DeleteConfirmModal from '../organisms/DeleteComfirmModal'

const ManageListTemplate = (props) => {
  const [tableList, setTableList] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [targetId, setTargetId] = useState(null)

  const {
    fetchDataApi,
    deleteDataApi,
    ADD_BUTTON_LABEL_I18NKEY,
    CONST_THEAD,
    CONST_SORT,
    canEdit,
    canDelete,
    EditModal
  } = props.props

  const fetchData = async () => {
    const {
      data: { data: details },
    } = await fetchDataApi()
    setTableList(details)
  }

  useEffect(() => {
    const loginTime = Cookies.get('login_time')
    checkSessionTimeout(loginTime)

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const deleteData = async () => {
    await deleteDataApi({ id: targetId })
    window.location.reload()
  }

  const openEditModal = (targetId=null) => {
    setIsEditModalOpen(true)
    setTargetId(targetId)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setTargetId(null)
  }

  const openDeleteModal = (targetId) => {
    setIsDeleteModalOpen(true)
    setTargetId(targetId)
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setTargetId(null)
  }

  if (!tableList) {
    return null
  }

  return (
    <div className='padding2'>
      <Button
        color='primary'
        i18nKey={ADD_BUTTON_LABEL_I18NKEY}
        disabled={!(canEdit && canDelete)}
        clickHandler={() => setIsEditModalOpen(true)}
      />
      <ListTable
        theadI18nKeyList={CONST_THEAD}
        tbodyList={tableList}
        sort={CONST_SORT}
        canEdit={canEdit}
        canDelete={canDelete}
        openEditModal={openEditModal}
        openDeleteModal={openDeleteModal}
      />
      {isEditModalOpen && (
        <EditModal
          targetId={targetId}
          closeEditModal={closeEditModal}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmModal
          closeDeleteModal={closeDeleteModal}
          deleteData={deleteData}
        />
      )}
    </div>
  )
}

export default ManageListTemplate
